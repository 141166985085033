@import "src/theme";

$vgap: 0.5em;

.launch-config {
  background-color: $white;
  border-radius: 10px;
  padding: 8px 10px;

  max-height: calc(20px + 1em);
  transition: max-height 500ms;
  overflow: hidden;

  .account {
    font-weight: 200;
  }

  .name {
    font-weight: 500;
    overflow: hidden;
  }

  .icon-type {
    width: 1.5em;
    height: 1.5em;
    stroke-width: 2px;
  }

  .description-container {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 500ms, margin-top 500ms, opacity 300ms;
  }

  .description {
    font-weight: 300;
  }

  .details-container {
    display: grid;

    grid-template-columns: auto 1fr;
    grid-auto-flow: row;
    grid-gap: 0 0.2em;

    transition: max-height 300ms, margin-top 300ms, opacity 300ms;

    max-height: 0;
    margin-top: 0;
    opacity: 0;
    overflow: hidden;

    .key {
      font-weight: 500;
    }

    .value {
      font-weight: 300;
    }
  }

  .time-container {
    font-weight: 300;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    transition: max-height 300ms, opacity 300ms;

    .time-header {
      font-weight: 500;
    }
  }
}

.launch-config[aria-selected='true'] {
  max-height: 30em;
}

.launch-config[aria-selected='true'] .details-container {
  max-height: 10em;
  margin-top: $vgap;
  opacity: 1;
  overflow: visible;
}

.launch-config:hover .description-container {
  max-height: 10em;
  margin-top: $vgap;
  opacity: 1;
}

.launch-config[aria-selected='true'] .description-container {
  max-height: 10em;
  margin-top: $vgap;
  opacity: 1;
}

.launch-config[aria-selected='true'] .time-container {
  max-height: 10em;
  opacity: 1;
}

.launch-config:hover {
  max-height: 30em;
}