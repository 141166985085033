@import "src/theme";


.field-label {
  text-transform: capitalize;
  white-space: nowrap;
}

.field-group {
  input, textarea, .field-value-ro {
    background: none;
    border: none;
    outline: none;
    border-bottom: 2px solid $light-gray;
    font-size: 16px;
    font-weight: 300;
    margin: -2px 0 2px;
  }

  textarea {
    font-family: monospace;
    padding: 8px;
    min-height: 10em;
  }
}

.invalid {
  .validation {
    font-size: 12px;
    font-weight: 300;
    color: $red;
  }

  input, textarea, .selected-container {
    border-bottom-color: $red !important;
  }
}

.select {
  .selected-container {
    flex-wrap: wrap;
    gap: 8px;
    border-bottom: 2px solid $light-gray;

    max-height: 11em;
    overflow-x: auto;
    //-webkit-mask-image: linear-gradient(180deg, $body-bg 9em, transparent);
  }

  .validation {
    margin-top: -20px;
  }

  .select-search {
    background: white;
    border-radius: 10px;
    color: $dark-gray;
    //height: 26px;
    font-size: 16px;

    .search-icon {
      margin-left: 6px;
    }

    input {
      border: none;
      background: none;
      font-weight: 300;
      outline: none;
      margin: 0;

      min-width: 20vw;
    }

    .search-close {
      margin: -3px 6px 0;
    }
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .selected-item, .unselected-item {
    border-radius: 10px;
    background: $white;
    line-height: 16px;
    padding: 5px 7px;
  }

  .unselected-item {
    background: $green;
    margin-top: -8px;
    margin-bottom: 8px;
    display: none;
  }

  .item-close {
    padding-left: 5px;
    margin-top: -3px;
  }

  .item-name {
    font-weight: 300;
  }

  .item-icon {
    margin: -2px 5px -3px 0;
  }

  .item-info {
    font-weight: 100;
  }
}

.selected-container:hover {
  .unselected-item {
    display: flex;
  }
}
